// Button variants
//
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons

@mixin button-variant(
  $background,
  $border,
  $pseudo-class: (
    hover: (
      background: lighten-and-saturate-color($background, $btn-lighten-amount, $btn-saturate-amount),
      border: lighten-and-saturate-color($background, $btn-lighten-amount, $btn-saturate-amount),
    ),
    focus: (
      background: $background,
      border: $background,
    ),
    active: (
      background: lighten-and-saturate-color($background, $btn-lighten-amount, $btn-saturate-amount),
      border: lighten-and-saturate-color($background, $btn-lighten-amount, $btn-saturate-amount),
    )
  )
) {
  color: color-yiq($background);
  @include gradient-bg($background);
  border-color: $border;
  @include box-shadow($btn-box-shadow);

  @include hover() {
    color: color-yiq(map-get($pseudo-class, 'hover', 'background'));
    @include gradient-bg(map-get($pseudo-class, 'hover', 'background'));
    border-color: map-get($pseudo-class, 'hover', 'border');
  }

  &:focus,
  &.focus {
    color: color-yiq(map-get($pseudo-class, 'focus', 'background'));
    @include gradient-bg(map-get($pseudo-class, 'focus', 'background'));
    border-color: map-get($pseudo-class, 'focus', 'border');
    @if $enable-shadows {
      @include box-shadow($btn-box-shadow, 0 0 0 $btn-focus-width rgba(mix(color-yiq($background), $border, 15%), 0.5));
    } @else {
      // Avoid using mixin so we can pass custom focus shadow properly
      box-shadow: $btn-focus-box-shadow;
    }
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    color: color-yiq($background);
    background-color: $background;
    border-color: $border;
    // Remove CSS gradients if they're enabled
    @if $enable-gradients {
      background-image: none;
    }
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    color: color-yiq(map-get($pseudo-class, 'active', 'background'));
    background-color: map-get($pseudo-class, 'active', 'background');
    @if $enable-gradients {
      background-image: none; // Remove the gradient for the pressed/active state
    }
    border-color: map-get($pseudo-class, 'active', 'border');

    &:focus {
      @if $enable-shadows and $btn-active-box-shadow != none {
        @include box-shadow($btn-active-box-shadow, 0 0 0 $btn-focus-width rgba(mix(color-yiq($background), $border, 15%), 0.5));
      } @else if $btn-active-box-shadow != none {
        // Avoid using mixin so we can pass custom focus shadow properly
        box-shadow: $btn-active-box-shadow;
      }
    }
  }
}
