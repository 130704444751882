// Used to format :hover and :focus.
$base-lighten-amount: 14%;
$base-saturate-amount: 1%;

// Buttons
$btn-lighten-amount: $base-lighten-amount;
$btn-saturate-amount: $base-saturate-amount;

// Forms
$input-margin-bottom: px-to-rem(17);
$input-valid-border-color: #3f648d;

// Forms Feedback
$form-feedback-line-height: px-to-rem(20);

// Tabs
$nav-tabs-margin-bottom: px-to-rem(32);
$nav-tabs-gap: px-to-rem(32);
$nav-tabs-padding-x: px-to-rem(12);
$nav-tabs-font-weight: 500;
$nav-tabs-link-default-color: rgba($gray-800, 0.5);
$nav-tabs-link-hover-color: $gray-800;
$nav-tabs-link-min-width: px-to-rem(120);
