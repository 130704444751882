html,
body,
:root {
  height: 100%;
  margin: 0;
  background-color: $body-bg;
}

a {
  cursor: pointer;
}
