.nav-tabs {
  padding-left: $nav-tabs-padding-x;
  padding-right: $nav-tabs-padding-x;
  border-bottom: $nav-tabs-border-width solid $nav-tabs-border-color;
  margin-bottom: $nav-tabs-margin-bottom;
  gap: $nav-tabs-gap;

  .nav-link {
    min-width: $nav-tabs-link-min-width;
    text-align: center;
    margin-bottom: -$nav-tabs-border-width;
    border: $nav-tabs-border-width solid transparent;
    font-weight: $nav-tabs-font-weight;
    color: $nav-tabs-link-default-color;
    @include border-top-radius($nav-tabs-border-radius);

    @include hover-focus() {
      border-color: $nav-tabs-link-hover-border-color;
      color: $nav-tabs-link-hover-color;
      transition: 300ms;
      animation-timing-function: ease-out;
    }

    &.disabled {
      color: $nav-link-disabled-color;
      background-color: transparent;
      border-color: transparent;
    }
  }

  .nav-link.active,
  .nav-item.show .nav-link {
    color: $nav-tabs-link-active-color;
    background-color: $nav-tabs-link-active-bg;
    border-color: $nav-tabs-link-active-border-color;
  }
}
