@use 'abstracts/functions';

::-webkit-scrollbar {
  width: functions.px-to-rem(8);
  height: functions.px-to-rem(8);
  border-radius: 4px;
}

::-webkit-scrollbar-track {
  width: functions.px-to-rem(4);
  height: functions.px-to-rem(4);
  border-radius: 4px;
  background-color: rgba(211, 211, 211, 0.35);
  margin: functions.px-to-rem(24) 0;
}

::-webkit-scrollbar-thumb {
  width: functions.px-to-rem(4);
  height: functions.px-to-rem(4);
  border-radius: 4px;
  background-color: rgba(211, 211, 211, 0.7);

  &:hover {
    background-color: rgba(208, 208, 208, 1);
  }
}
