@use 'abstracts/functions';
@import 'abstracts/colors';

.modal-header {
  &.border-bottom {
    border: 1px solid $gray-300;
    box-shadow: 0px 1px 2px rgba($black, 0.25);
  }

  .btn-close {
    width: functions.px-to-rem(44);
    height: functions.px-to-rem(48);
    border-radius: functions.px-to-rem(8);
    background-color: #eff1f2;
  }
}

.modal-footer {
  gap: functions.px-to-rem(10);

  .btn-block {
    max-width: fit-content;
    padding: functions.px-to-rem(10) functions.px-to-rem(14);
    border-radius: 0.25rem;
  }
}
