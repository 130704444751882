@use 'abstracts/functions';

.no-btn {
  @extend .btn;
  padding: 0;

  &:focus {
    box-shadow: none;
  }
}

.btn-sm {
  @extend .btn;
  border-radius: $btn-border-radius-sm;
  padding: $btn-padding-y-sm $btn-padding-x-sm;
  line-height: 1rem;
  border: none;
}

.btn-lg {
  @extend .btn;
  border-radius: $btn-border-radius-lg;
  padding: $btn-padding-y-lg $btn-padding-x-lg;
}

.btn-link {
  border-width: 0px;
  padding: 0;
  font-size: $font-size-sm;
}

.btn-back {
  display: flex;
  justify-content: center;
  align-items: center;

  border: none;
  color: $gray-800;
  width: px-to-rem(44);
  height: px-to-rem(48);
  border-radius: px-to-rem(12);
  background-color: rgba($gray-300, 0.8);
}

.btn-block {
  max-width: inherit;
  padding: functions.px-to-rem(9) functions.px-to-rem(14);
  font-size: $font-size-sm;
  border-radius: 0.5rem;
  color: $white;
}

@each $color-name, $color-value in $theme-colors {
  .btn-#{$color-name} {
    @include button-variant($color-value, $color-value);
  }
}

.btn-light {
  @include button-variant(
    $light,
    $light,
    (
      hover: (
        background: transparentize($secondary, 0.65),
        border: transparent,
      ),
      focus: (
        background: $light,
        border: $light,
      ),
      active: (
        background: $light,
        border: $light,
      ),
    )
  );
}

button:disabled {
  cursor: not-allowed;
}
