input[type='checkbox'] {
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
  outline: none;
  content: none;
}

input[type='checkbox']:before {
  font-family: 'Font Awesome 5 Pro';
  content: '\f00c';
  line-height: 16px;
  font-size: 13px;
  text-align: center;

  display: block;
  width: 16px;
  height: 16px;
  border: 1px solid rgba($gray-800, 0.2);
  border-radius: 2px;

  background: rgba($gray-200, 0.5);
  color: transparent !important;
}

input[type='checkbox']:checked:before {
  background: $primary;
  color: white !important;
  font-weight: bold;
  border: none;
}
