@use 'sass:math';

/**
 *  Adds a prefix to each of the map's keys.
 *  @arg map - map to be modified.
 *  @arg prefix - prefix to be added.
 */
@function prefix-keys($map, $prefix) {
  $new-map: ();

  @each $key, $value in $map {
    $new-map: map-merge(
      $new-map,
      (
        '#{$prefix}-#{$key}': $value,
      )
    );
  }

  @return $new-map;
}

/**
 *  Converts from `px` to `rem`.
 *  @arg {Number} px-value - value (number of pixels), as number, to be converted.
 */
@function px-to-rem($px-value) {
  @return math.div($px-value, 16) * 1rem;
}

/**
 *  Applies a brightening and saturating filter to a color.
 *  @arg {Color} color.
 *  @arg {Percentage} lighten-amount - percentage.
 *  @arg {Percentage} saturate-amount - percentage.
 */
@function lighten-and-saturate-color($color, $lighten-amount, $saturate-amount) {
  @return saturate(lighten($color, $lighten-amount), $saturate-amount);
}
