.header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;

  border-bottom: solid px-to-rem(1) #e2e2e2;
  color: $gray-800;

  padding-top: px-to-rem(19);
  padding-left: px-to-rem(9);
  padding-right: px-to-rem(32);
  padding-bottom: px-to-rem(19);
  margin-bottom: px-to-rem(32);

  .header-title {
    margin-top: px-to-rem(10);
    margin-bottom: px-to-rem(10);
    margin-left: px-to-rem(55);
    font-weight: normal;
  }

  .header-text-panel {
    text-align: left;
    flex-flow: column nowrap;
    margin-top: px-to-rem(2);
    margin-left: px-to-rem(55);

    .header-title {
      margin: 0 !important;
    }

    *:not(.header-title) {
      font-weight: 300;
      font-size: px-to-rem(14);
    }
  }

  .header-btn {
    margin-left: auto;
  }

  .header-btn-panel {
    margin-left: auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: px-to-rem(16);

    button {
      @extend .header-btn;
    }
  }

  .header-back-btn {
    margin-left: px-to-rem(22);
  }
}
