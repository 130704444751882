.form-group {
  > *:not(:last-child) {
    .form-control {
      margin-bottom: $input-margin-bottom + ($form-feedback-margin-top + $form-feedback-line-height);
    }
  }
}

.form-control {
  border: none;
  box-shadow: $input-box-shadow;
  padding: $input-border-width; // simulate `box-shadow` as a `border`

  &.invalid {
    box-shadow: $input-invalid-box-shadow;

    .invalid-feedback {
      display: block;
    }
  }

  &.valid {
    box-shadow: $input-valid-box-shadow;
  }

  &.readonly {
    color: $input-readonly-color;
    box-shadow: $input-readonly-box-shadow;

    * {
      cursor: default;
    }
  }

  > .form-container {
    @include border-radius($input-border-radius, 0);

    > :first-child {
      display: flex;
      align-self: stretch;

      .input-control {
        padding-right: $input-padding-x;
      }
    }
  }

  %form-control-placeholder-active {
    font-size: 0.875rem;
    top: 0;
    transform: translateY(-100%);
  }

  .form-control-placeholder {
    cursor: text;
    left: $input-padding-x - 0.25rem;
    padding: 0 0.25rem;
    transition: transform 0.2s, font-size 0.2s;
    z-index: 4;

    &.active {
      @extend %form-control-placeholder-active;
    }
  }

  .form-focus-container {
    flex: 1 1 auto;

    &:focus-within {
      & ~ .form-control-placeholder {
        @extend %form-control-placeholder-active;
      }
    }
  }

  .input-control {
    background: transparent;
    border: none;
    color: inherit;
    display: flex;
    flex: 1 1 auto;
    font-weight: inherit;
    padding: $input-padding-y $input-padding-x;
    padding-right: 0;

    &:focus {
      outline: none;
    }

    // Remove arrows from <input type="number"/>
    // Chrome, Safari, Edge, Opera
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    // Remove arrows from <input type="number"/>
    // Firefox
    &[type='number'] {
      -moz-appearance: textfield;
    }
  }
}

.invalid-feedback {
  line-height: $form-feedback-line-height;
}

.enumerated-forms {
  .field-title {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    .index {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 1rem;
      background-color: $primary;
      color: $white;
      width: px-to-rem(24);
      height: px-to-rem(24);
      border-radius: 50%;
      font-size: px-to-rem(14);
    }

    * {
      margin-bottom: 0;
    }
  }
}
