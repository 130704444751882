$white: #fff;
$gray-100: #f9f9f9;
$gray-200: #e6e9e9;
$gray-300: #edf0f0;
$gray-400: #ced4da;
$gray-500: #bdbcbc;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #424242;
$gray-900: #212529;
$black: #000;

$blue: #204a77;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #c80c0c;
$orange: #fd7e14;
$yellow: #f0be84;
$green: #81c784;
$teal: #20c997;
$cyan: #17a2b8;

$body-bg: #fafbfd;
$primary: $blue;
$secondary: #779ccf;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $gray-200;
$dark: $gray-800;
