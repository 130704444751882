$roboto-font-weight: (
  'Light': 300,
  'Regular': 400,
  'Medium': 500,
);

@each $name, $font-weight in $roboto-font-weight {
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: $font-weight;
    src: url('./tff/Roboto-#{$name}.ttf');
  }
}
